.PaperComp {
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.6875rem;
}

.boxShadow {
  
  border-radius: 0.3125rem;
  background: #fff;

  /* shadow */
  box-shadow: -1px 0px 31px 0px rgba(17, 14, 61, 0.1);
}


.boxSize{
  justify-content: flex-start;
 max-width: 40rem;
}

.adjutsment{
  flex-direction: row;
    align-content: center;
    justify-content: center;
}
