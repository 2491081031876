.subtitleComp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
  margin-top: 8rem;
  max-width: 55.375rem;
  gap: 1.5rem;
}
