.heroComp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
  gap: 1.5rem;
  h1 {
    max-width: 47rem;
  }
}

.logosX {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

/* Path: src/sections/hero/hero.css */
/* videopaly */
.video {
  border-radius: 1.25rem;
  cursor: pointer;
  width: auto;
  height: auto;
  box-shadow: 0rem 0.25rem 1.875rem 0rem rgba(0, 0, 0, 0.08);
}

.play-container {
  display: flex;
  justify-content: center;
    &_button {
      background-color: transparent;
      border-color: transparent;
    }

    input[type=range] {
      height: 1rem;
      margin: 0.625rem 0;
      width: 80%;
    }

    input[type=range]:focus {
      outline: none;
    }

    input[type=range]::-webkit-slider-runnable-track {
      width: 100%;
      height: 0.625rem;
      cursor: pointer;
      background: #D9D9D9;
      border-radius: 0rem;
    }
    input[type=range]::-webkit-slider-thumb {
      height: 0.5625rem;
      width: 0.3125rem;
      background: #000000;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -0.0313rem;
    }
    input[type=range]:focus::-webkit-slider-runnable-track {
      background: #D9D9D9;
    }
    input[type=range]::-moz-range-track {
      width: 100%;
      height: 0.625rem;
      cursor: pointer;
      background: #D9D9D9;
    }
    input[type=range]::-moz-range-thumb {
      height: 0.5625rem;
      width: 0.3125rem;
      background: #000000;
      cursor: pointer;
    }
    input[type=range]::-ms-track {
      width: 100%;
      height: 0.625rem;
      cursor: pointer;
      background: transparent;
      border-color: transparent;
      color: transparent;
    }
    input[type=range]::-ms-fill-lower {
      background: #D9D9D9;
    }
    input[type=range]::-ms-fill-upper {
      background: #D9D9D9;
    }
    input[type=range]::-ms-thumb {
      margin-top: 0.0625rem;
      height: 0.5625rem;
      width: 0.3125rem;
      background: #000000;
      cursor: pointer;
    }
    input[type=range]:focus::-ms-fill-lower {
      background: #D9D9D9;
    }
    input[type=range]:focus::-ms-fill-upper {
      background: #D9D9D9;
    }

}
