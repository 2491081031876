.text-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: auto;
  align-content: center;
  align-items: baseline;
  font-size: 1.2rem;
  color: var(--text-black-2);
}

.text-left svg {
  margin-left: 5px;
  margin-right: 5px;
  width: 20px;
  height: 20px;
}

.logo{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
    width: auto;
}
.logo svg {
  width: 7.0625rem !important;
  height: 0.625rem !important;
  gap: 0.27313rem;
}

.text-left.mobile {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
  align-items: baseline;
  width: auto;
  font-size: 1.2rem;
  color: var(--text-black-2);
}

.mobile.text-left svg {
  margin-left: 5px;
  margin-right: 5px;
  width: 20px;
  height: 20px;
}

.mobile .logo {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: baseline;
}
.mobile .logo svg {
  width: 7.0625rem !important;
  height: 0.625rem !important;
  gap: 0.27313rem;
}
