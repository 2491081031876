.textcardDev {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: auto;
    width: 100%;
    height: auto;
  }
  .textcardDev > * {
    max-width: 32.75rem;
    min-width: 5rem;
  }

  .cardCompDev {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .textdecorationDev {
    border-radius: 6.1875rem;
    display: flex;
    padding: 0.0625rem 1.03344rem 0.14938rem 1.04594rem;
    justify-content: center;
    align-items: flex-start;
    color: var(--error-color);
    background: var(--error-color-2);
  }
