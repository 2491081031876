body {
  margin: 0;
  overflow-x: hidden;
  font-family: "Inter", sans-serif;
  font-family: "Inter", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


/* Path: src/App.css */

*{
  margin: 0;
  padding: 0;
}



:root{
  --white-color: white;
  --secondarycolor: #282c34;

  --text-black: #110E3D;
  --text-black-2: #7F7B7B;



  --sucess-color: #6CBF84;
  --sucess-color-2: rgba(108, 191, 132, 0.15);

  --warning-color: #EB8155;
  --warning-color-2: rgba(235, 129, 85, 0.15);

  --error-color: #F25685;
  --error-color-2: rgba(242, 86, 133, 0.15);

  --info-color: #5682F2;
  --info-color-2: rgba(86, 130, 242, 0.15);


  --purple-color: #9747FF;
  --purple-color-2: rgba(118, 44, 230, 0.15);

  --gray-color: #595858;
  --gray-color-2: rgba(83, 83, 83, 0.15);


  --text-font: 'Inter', sans-serif;
  --font-size: 3rem;
  --font-weight: 500;

}


.title{
  font-family: var(--text-font);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  color: var(--text-black);
  margin-bottom: 1rem;
}

.title2{
  font-family: var(--text-font);
  font-size: 2.5rem;
  font-weight: 400;
  color: var(--text-black);
  margin-bottom: 1rem;
}

.subtitle{
  font-family: var(--text-font);
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--text-black);
  margin-bottom: 1rem;
}

.paragrafo{
  font-family: var(--text-font);
  font-size: 1.125rem;
  font-weight: 400;
  color: var(--text-black-2);
  margin-bottom: 1rem;
  
}

.paragrafoBlack{
  font-family: var(--text-font);
  font-size: 1.125rem;
  font-weight: 400;
  color: var(--text-black);
  margin-bottom: 1rem;
  
}


.smalltext{
  font-family: var(--text-font);
  font-size: 1rem;
  font-weight: 400;
  color: var(--text-black-2);
  margin-bottom: 1rem;
  
}

