.hero-industries {
    position: relative;
    width: 95%;
    height: 600px;
    background-image: url("https://drive.google.com/uc?id=1FP4d7WZSo9R6J_Wzqz3HOQaLMrOc_rYi");
    background-size: cover;
    margin-block-start: 6rem;
    margin-inline: 2%;
    border-radius: 35px;

    .redirect-vide {
        position: absolute;
        background-image: url("../assets/tumbnail.png");
        width: 9rem;
        aspect-ratio: 1;
        margin-block-start: 2.1875rem;
        margin-inline-start: 1.875rem;
    }

    .go-to {
        position: absolute;
        bottom: 0;
        width: 8.125rem;
        aspect-ratio: 1;
        right: 35px;
        margin-block-end: 35px;
        &:hover {
            animation: spin-go-to 4s linear infinite;
        }
    }

    .content-center {
        display: grid;
        place-items: center;
        height: 100%;
    }
}

@keyframes spin-go-to {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}