.PrivacyComp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
  align-items: center;
  width: 100%;
  height: 50vh;
  align-content: center;
}


.tagLine{
    display: flex;
    
    padding: 0rem 8rem;
    flex-direction: column;
    align-items: center;
    text-align: center;

}
.privacyText {
  display: flex;
  padding-left: 0px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.625rem;
  text-align: justify;
}


.greenTag {
    border-radius: 6.1875rem;
    display: flex;
    padding: 0.0625rem 1.03344rem 0.14938rem 1.04594rem;
    justify-content: center;
    align-items: flex-start;
    color: var(--sucess-color);
    background: var(--sucess-color-2);
  }


  .orangeTag {
    border-radius: 6.1875rem;
    display: flex;
    padding: 0.0625rem 1.03344rem 0.14938rem 1.04594rem;
    justify-content: center;
    align-items: flex-start;
    color: var(--warning-color);
    background: var(--warning-color-2);
  }


  .blueTag {
    border-radius: 6.1875rem;
    display: flex;
    padding: 0.0625rem 1.03344rem 0.14938rem 1.04594rem;
    justify-content: center;
    align-items: flex-start;
    color: var(--info-color);
    background: var(--info-color-2);
  }


  .redTag {
    border-radius: 6.1875rem;
    display: flex;
    padding: 0.0625rem 1.03344rem 0.14938rem 1.04594rem;
    justify-content: center;
    align-items: flex-start;
    color: var(--error-color);
    background: var(--error-color-2);
  }


  .purpleTag {
    border-radius: 6.1875rem;
    display: flex;
    padding: 0.0625rem 1.03344rem 0.14938rem 1.04594rem;
    justify-content: center;
    align-items: flex-start;
    color: var(--purple-color);
    background: var(--purple-color-2);
  }


  .grayTag {
    border-radius: 6.1875rem;
    display: flex;
    padding: 0.0625rem 1.03344rem 0.14938rem 1.04594rem;
    justify-content: center;
    align-items: flex-start;
    color: var(--gray-color);
    background: var(--gray-color-2);
  }